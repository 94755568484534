var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.card["submit_status"] !== "not_stated"
    ? _c(
        "el-card",
        { staticClass: "box-card" },
        [
          _c(
            "div",
            {
              staticClass: "clearfix box-card-date",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "span",
                { staticStyle: { float: "right", padding: "3px 0" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.getTime(_vm.card.window_from, _vm.GET_TIME)) +
                      " - " +
                      _vm._s(_vm.getTime(_vm.card.window_to, _vm.GET_TIME)) +
                      " "
                  ),
                ]
              ),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.getTime(_vm.card.window_from, _vm.DATE_FORMAT_RU))
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "text card timeslot-list" }, [
            _c("span", { staticClass: "timeslot-list_card" }, [
              _vm._v("Терминал:"),
            ]),
            _c("span", { staticClass: "timeslot-list_meaning" }, [
              _vm._v(_vm._s(_vm.card.unload_name)),
            ]),
          ]),
          _c("el-divider", { staticClass: "box-card_divider" }),
          _c("div", { staticClass: "text card timeslot-list" }, [
            _c("span", { staticClass: "timeslot-list_card" }, [
              _vm._v("Культура:"),
            ]),
            _c("span", { staticClass: "timeslot-list_meaning" }, [
              _vm._v(_vm._s(_vm.card.culture_name)),
            ]),
          ]),
          _c("el-divider", { staticClass: "box-card_divider" }),
          _c("div", { staticClass: "text card timeslot-list" }, [
            _c("span", { staticClass: "timeslot-list_card" }, [
              _vm._v("Поставщик:"),
            ]),
            _c("span", { staticClass: "timeslot-list_meaning" }, [
              _vm._v(_vm._s(_vm.card.exporter_name)),
            ]),
          ]),
          _c("el-divider", { staticClass: "box-card_divider" }),
          _c("div", { staticClass: "text card timeslot-list" }, [
            _c("span", { staticClass: "timeslot-list_card" }, [
              _vm._v("Номер авто:"),
            ]),
            _c("span", { staticClass: "timeslot-list_meaning" }, [
              _vm._v(_vm._s(_vm.card.num_auto)),
            ]),
          ]),
          _c("el-divider", { staticClass: "box-card_divider" }),
          _c("div", { staticClass: "text card timeslot-list" }, [
            _c("span", { staticClass: "timeslot-list_card" }, [
              _vm._v("Статус:"),
            ]),
            _c(
              "span",
              { staticClass: "timeslot-list_meaning" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      "padding-left": "24px",
                      "padding-right": "24px",
                    },
                    attrs: {
                      plain: "",
                      type: _vm.getCarNumber(_vm.item.timeslot_id)
                        ? "success"
                        : "danger",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.routerPush(_vm.item.timeslot_id)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-truck icon-btn" }),
                    _vm._v(
                      _vm._s(
                        _vm.getCarNumber(_vm.item.timeslot_id) ||
                          " Введите данные автомобиля"
                      ) +
                        _vm._s(
                          _vm.confirmIdArray.includes(_vm.item.timeslot_id)
                            ? " - подтвержден"
                            : ""
                        ) +
                        " "
                    ),
                  ]
                ),
                _vm.getCarNumber(_vm.item.timeslot_id) &&
                !_vm.confirmIdArray.includes(_vm.item.timeslot_id)
                  ? _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { type: "warning" },
                        on: {
                          click: function ($event) {
                            return _vm.submitTimeslot(_vm.item.timeslot_id)
                          },
                        },
                      },
                      [_vm._v(" Подтвердить тайм слот ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "text card timeslot-list" }, [
            _c("span", { staticClass: "timeslot-list_card" }, [
              _vm._v("Статус:"),
            ]),
            _c(
              "span",
              { staticClass: "timeslot-list_meaning" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "danger" },
                    on: {
                      click: function ($event) {
                        return _vm.openDelete(_vm.item.timeslot_id)
                      },
                    },
                  },
                  [
                    _vm._v(" Удалить "),
                    _c("i", { staticClass: "el-icon-delete red-btn" }),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }