<template>
  <el-card v-if="card['submit_status'] !== 'not_stated'" class="box-card">
    <div slot="header" class="clearfix box-card-date">
      <span style="float: right; padding: 3px 0">
        {{ getTime(card.window_from, GET_TIME) }} -
        {{ getTime(card.window_to, GET_TIME) }}
      </span>
      <span>{{ getTime(card.window_from, DATE_FORMAT_RU) }}</span>
    </div>
    <div class="text card timeslot-list">
      <span class="timeslot-list_card">Терминал:</span>
      <span class="timeslot-list_meaning">{{ card.unload_name }}</span>
    </div>
    <el-divider class="box-card_divider" />

    <div class="text card timeslot-list">
      <span class="timeslot-list_card">Культура:</span>
      <span class="timeslot-list_meaning">{{ card.culture_name }}</span>
    </div>
    <el-divider class="box-card_divider" />

    <div class="text card timeslot-list">
      <span class="timeslot-list_card">Поставщик:</span>
      <span class="timeslot-list_meaning">{{ card.exporter_name }}</span>
    </div>
    <el-divider class="box-card_divider" />
    <div class="text card timeslot-list">
      <span class="timeslot-list_card">Номер авто:</span>
      <span class="timeslot-list_meaning">{{ card.num_auto }}</span>
    </div>
    <el-divider class="box-card_divider" />

    <div class="text card timeslot-list">
      <span class="timeslot-list_card">Статус:</span>

      <span class="timeslot-list_meaning">
        <el-button
          plain
          style="padding-left: 24px; padding-right: 24px"
          :type="getCarNumber(item.timeslot_id) ? 'success' : 'danger'"
          @click="routerPush(item.timeslot_id)"
        >
          <i class="el-icon-truck icon-btn" />{{
            getCarNumber(item.timeslot_id) || ' Введите данные автомобиля'
          }}{{
            confirmIdArray.includes(item.timeslot_id) ? ' - подтвержден' : ''
          }}
        </el-button>
        <el-button
          v-if="
            getCarNumber(item.timeslot_id) &&
            !confirmIdArray.includes(item.timeslot_id)
          "
          type="warning"
          class="btn"
          @click="submitTimeslot(item.timeslot_id)"
        >
          Подтвердить тайм слот
        </el-button>
      </span>
    </div>
    <div class="text card timeslot-list">
      <span class="timeslot-list_card">Статус:</span>

      <span class="timeslot-list_meaning">
        <el-button type="danger" @click="openDelete(item.timeslot_id)">
          Удалить <i class="el-icon-delete red-btn" />
        </el-button>
      </span>
    </div>
  </el-card>
</template>

<script>
import { DATE_FORMAT, DATE_FORMAT_RU, GET_TIME } from '../../../constants/date'
import { getIsAndroid, getTime } from '@/core'
import { timeslotStatuses } from '@/views/manager/data/manager'

export default {
  name: 'TimeSlotCard',
  props: ['card'],
  data() {
    return {
      GET_TIME,
      DATE_FORMAT,
      DATE_FORMAT_RU,
      timeslotStatuses,
    }
  },
  methods: {
    getTime,
    open(card) {
      this.$emit('open', card)
    },
  },
}
</script>
<style lang="sass">
$bluColor: #4CA7D8
$redColor: #F56C6C
$mainFont: #606266

.box-card
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 6px
  background: #FFFFFF
  border: 1px solid #E4E7EA!important
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1)!important
  border-radius: 4px!important
  margin-bottom: 20px
  &_divider
    margin: 8px 0
    background: #E4E7ED

  &-date
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    & > span
      font-family: Roboto
      font-style: normal
      font-weight: 600
      font-size: 16px
      line-height: 22px
      font-feature-settings: 'tnum' on, 'lnum' on
      color: #606266
.el-card__header
  display: flex
  flex-direction: row
  align-items: center
  padding: 5px 10px

  background: #D3D4D6
  width: 100%
  padding: 8px 15px !important
  color: white
  border-radius: 4px
  color: #606266
.el-card__body
  width: 100%
  padding: 16px 5px!important

.timeslot-list
  display: flex
  justify-content: space-between
  &_card
    color: #303133
    font-weight: 600
    font-size: 14px
  &_meaning
    color: #303133
    font-weight: 400
    font-size: 14px

  &_status
    color: $bluColor
    font-weight: 800
    text-transform: uppercase
    size: 16px
  .timeslot-list_item
    display: flex
    flex-direction: column
    font-weight: bold
    align-items: flex-end
    & > button
      margin-bottom: 12px
      width: 100%

  .timeslot-list_meaning
    text-align: right
.timeslot-circle-btn
  margin-top: 20px
  width: 40px
  height: 40px
  border-radius: 100px!important
  font-size: 18px!important
  .red-btn
    color: red
  .yellow-btn
    color: orange
  i
    @media only screen and (max-width: 550px)
      &:before
        width: 40px
        height: 40px
        border-radius: 100px!important
        font-size: 32px!important
        margin-left: 12px

.timeslot-list_number
  align-items: center
.timeslot-list_item-number
  font-size: 18px
  color: $bluColor
</style>
